<template>
	<div class="page">
		<div class="bannerBox">
			<p>{{ lang == 6 ? '宝爱捷（中国）' : 'PAIG (China) ' }}<br>{{ lang == 6 ? '发展历程' : 'Milestone' }}</p>
		</div>
		<div class="content">
			<div class="itemBox" v-for="(item, index) in list" :key="index">
				<!-- :class="index!=0 ? (list[index-1].items.length%2===0 ? '' : 'rightP') : ''" -->
				<p :class="item.items[0].position == 'left' ? '' : 'rightP'">{{ item.year.year_id }}</p>
				<!-- <viewer :images="item.items"> -->
				<div v-for="(itemObj, j) in item.items" :key="j + 'obj'">
					<!-- index!=0 ? (list[index-1].items.length%2===0 ? ((j%2)===0) : ((j%2)!==0) ) : (j%2)===0 -->
					<div class="item" v-if="itemObj.position == 'left'">
						<!-- <img :src="itemObj.image" v-if="itemObj.image" /> -->

						<el-image class="item-img" :src="itemObj.image" mode="widthFit"
							:preview-src-list="previewList"></el-image>
						<div class="textBox">
							<p>{{ itemObj.business_time }}</p>
							<p class="yellowLine"></p>
							<p v-html="itemObj.title"></p>
						</div>
					</div>
					<div class="item rightItem" v-else>
						<div class="textBox">
							<p>{{ itemObj.business_time }}</p>
							<p class="yellowLine"></p>
							<p v-html="itemObj.title"></p>
						</div>
						<!-- <img :src="itemObj.image"  /> -->
						<el-image class="item-img" :src="itemObj.image" v-if="itemObj.image" mode="widthFit"
							:preview-src-list="previewList"></el-image>
					</div>
				</div>
				<!-- </viewer> -->

			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		props: {

		},
		data() {
			return {
				list: [],
				previewList: []
			}
		},
		methods: {
			getInfo() {
				this.$api.getCourseList({
					cate_no: 'few34w4g'
				}, {
					page: 1,
					limit: 100
				}).then(res => {
					var list = res.list
					let position = ''
					list.forEach((item, index) => {
						item.items.forEach((child, j) => {
							child.position = position == '' ? 'left' : (position == 'left' ?
								'right' : 'left')
							position = child.position
							this.previewList.push(child.image)
						})
					})
					this.list = list
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 0;
	}

	.bannerBox {
		width: 100%;
		height: 440px;
		background: url(../../assets/historyBg.png) no-repeat top/100% 100%;
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 50px;
		font-weight: bold;
		color: #FFF;
		padding: 217px 0 0 111px;
		text-transform: uppercase;
		line-height: normal;
	}

	.content {
		background: linear-gradient(180deg, #010100 0%, #101621 100%);
		color: #FFF;
		padding: 214px 0;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			width: 1px;
			height: 100%;
			left: 50%;
			background-color: #97233F;
			top: 0;
			z-index: 1;
		}

		.itemBox {
			position: relative;
			z-index: 2;
			width: 1417px;
			margin: 0 auto;

			>p {
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 100px;
				font-weight: bold;
				color: #97233F;
				display: inline-block;
				position: relative;
				left: 780px;
				margin-bottom: 61px;
			}

			.rightP {
				left: 406px;
			}

			.item {
				position: relative;
				display: flex;
				font-size: 26px;
				font-weight: bold;
				font-family: SourceHanSansCN-Bold, sans-serif;
				align-items: flex-end;
				margin-bottom: 150px;

				.textBox {
					width: calc(100% - 850px);
				}

				.yellowLine {
					display: inline-block;
					margin: 20px 0;
				}

				.item-img {
					width: 800px;
					height: 400px;
					border-radius: 32px;
					margin-right: 50px;
				}
			}

			.rightItem {
				text-align: right;

				.item-img {
					margin-right: 0;
					margin-left: 50px;
				}
			}
		}
	}
</style>